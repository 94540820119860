/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}

.login-header {
  margin-bottom: 30px;
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  //@include border-radius(10px);
  font-size: 14px;
  overflow: hidden;
}

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1;
  background-color: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
  @media screen and (max-width: 575px) {
    width: 100%;
    order: 2;
  }
  @media screen and (max-width: 580px) {
    padding: 8px 15px;
  }
  .app-login-content-inner {
    flex: 0.7;
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    @media screen and (min-width: 580px) {
    justify-content: center;
      //justify-content: space-between;
    }
    @media screen and (max-width: 580px) {
      flex: 1;
      gap: 20px;
      //justify-content: space-between;
    }
    .mobile-logo-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40px;
      }
      .change-theme-wrapper {
        .mode-btn {
          width: 32px;
        }
      }
    }
    .mobile-hint-wrapper {
      display: flex;
      gap: 5px;
      align-items: center;
      .mobile-hint-logo-wrapper {
        img {
          max-width: 60px;
        }
      }
      .hint-box {
        background-image: var(--secondary-glow);

        color: var(--danger-text);
        font-size: 14px;
        border-radius: 5px;
        @media screen and (max-width: 580px) {
          font-size: 9px;
        }
        .hint-row {
          padding: 15px;
          @media screen and (max-width: 1100px) {
            padding: 9px;

          }
        }
      }
    }
    .login-form {
      form {
        fieldset {
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media screen and (max-width: 580px) {
           gap: 12px;
          }
          .sign-input-labels {
            font-size: 13px;
            color: var(--text-color);
            opacity: 0.8;
            @media screen and (max-width: 580px) {
              font-size: 11px;
            }
          }
          .captcha-input-wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            gap: 10px;
            @media screen and (max-width: 1100px) {
              flex-direction: column;
            }
            @media screen and (max-width: 580px) {
              gap: 7px;
              flex-direction: column;
            }
          }
          .MuiFormControl-root {
            margin: 0;
          }
          .forgot-password-wrapper {
            @media screen and (max-width: 580px) {
             font-size: 10px;
            }
          }
          .captcha-wrapper {
           display: flex;
            flex: 1;
            img {
              flex: 1;
              border-radius: 20px;
              //width: 220px;
            }
          }
          .hint-box {
            background-image: var(--secondary-glow);

            color: var(--danger-text);
            font-size: 14px;
            border-radius: 5px;
            .hint-row {
              padding: 15px;
            }
          }
          .timer-wrapper {
            font-size: 13px;
            display: flex;
            gap: 5px;
            justify-content: center;
            color: var(--text-color);
          }
          .rules-check-wrapper {
            .MuiFormControlLabel-root {
              align-items: center;
              gap: 5px;
              @media screen and (max-width: 580px) {
                gap: 2px;
              }
              .MuiButtonBase-root {
                color: var(--secondary);
                @media screen and (max-width: 580px) {
                  padding: 4px 12px 4px 0;
                }


              }

              .MuiTypography-root {
                @media screen and (max-width: 580px) {
                  font-size: 11px !important;
                }
                .rules-desc {
                  color: var(--text-color);
                }
              }
            }
          }
          .validity-wrapper {
            font-size: 15px;
            display: flex;
            gap: 5px;
            .validity-desc {
              color: var(--text-color);
            }
            .validity-seconds {
              color: var(--secondary-color);
              font-weight: 500;
            }
          }
          .confirm-btn-wrapper {
            flex: 1;
            .confirm-btn {
              background-image: var(--secondary-btn-gradient);
              //color: var(--text-color);
              border-radius: 10px;
              span {
                color: var(--white);
              }
            }

          }
          .buttons-wrapper {
            display: flex;
            flex-wrap: nowrap;
            gap: 12px;
            .confirm-btn-wrapper {
              flex: 2;
              .confirm-btn {
                background-image: var(--secondary-btn-gradient);
                //color: var(--text-color);
                border-radius: 10px;
                span {
                  color: var(--white);
                }
              }

            }
            .cancel-btn-wrapper {
              flex: 1;
              .cancel-btn {
                background-image: var(--button-grey-dark);
                color: var(--text-color);
                border-radius: 10px;
                span {
                  color: var(--text-color);
                }
              }
            }
          }
        }
      }
    }
    .home-page {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 580px) {
       font-size: 10px;
      }
    }
  }
}

.app-login-header {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 580px) {
    margin-bottom: 0;
  }
  .sign-title {
    color: var(--secondary);
    font-size: 24px;
    font-weight: 700;
    @media screen and (max-width: 580px) {
    font-size: 21px;
    }
  }
  .sign-desc {
    display: flex;
    gap: 5px;
    color: var(--text-color);
    font-size: 14px;
    @media screen and (max-width: 580px) {
      font-size: 10px;
    }
  }
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: var(--table-odd-bg);
  padding: 20px 35px 20px;
  width: 40%;
  order: 2;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1100px) {
    padding: 20px 15px 20px;
  }

  @media screen and (max-width: 580px) {
    width: 100%;
    order: 1;
    display: none !important;
  }
  .app-logo-content-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .change-theme-wrapper {
      cursor: pointer;
    }
    .logo-wrapper {
      //position: absolute;
      //top: 4%;
      //left: 4%;
      img {
        width: 75px;
      }
    }
  }

  .lock-img {
    max-width: 400px;
    @media screen and (max-width: 1100px) {
     max-width: 300px;
    }
  }
  .hint-box {
    background-image: var(--secondary-glow);

    color: var(--danger-text);
    font-size: 14px;
    border-radius: 5px;
    @media screen and (max-width: 1100px) {
      font-size: 12px;
    }
    .hint-row {
      padding: 15px;
      @media screen and (max-width: 1100px) {
       padding: 9px;
      }
    }
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}
