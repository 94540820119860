/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input {
    direction: ltr;
}

/*Table*/

.MuiTablePagination-caption {
    direction: ltr;
}

#tsparticles {

    position: absolute;
}
.widget-card{
    height: 320px;
    background-color: #3682ce;
    border-radius: 8px;
}
.MuiButton-root{
    color: unset !important;
}
