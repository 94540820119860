/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
  display: flex;
  flex-flow: column;
}

.app-wrapper {
  padding: 20px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}