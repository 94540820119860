/*App Layout Styles*/

#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%;
}

.app-container {
  @include display-flex(flex, row, nowrap);
  width: 100%;
}

.app-sidebar {
  @include display-flex(flex, column, nowrap);
  width: $side-nav-width;
  min-width: $side-nav-width;
  max-width: $side-nav-width;
  @media screen and (max-width: 580px) {
    width: unset;
    min-width: unset;
    max-width: unset;
  }
}

.app-main-container {
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow: hidden;
  background-color: white;
}

.app-header {
  min-height: $app-bar-height;
}

div.flex-column-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
div.flex-container {
  display: flex;
  gap: 16px;
}

.panel-content-wrapper {
  padding: 25px;
}

.app-main-content-wrapper {
  flex: 1;
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
}

.app-container {
  &.fixed-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $side-nav-width;
      right: 0;

      @media screen and (max-width: 1199px) {
        left: 0;
        right: 0;
      }
    }
  }

  &.collapsible-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: 0;
      right: 0;
    }
  }

  &.mini-drawer {
    .app-sidebar-content {
      .side-nav:hover {
        .nav-menu {
          .menu {
            min-width: unset;
            .side-bar-content {
              display: flex;
              gap: 5px;
              flex-direction: row;
              align-items: center;
              .nav-text {
                display: unset;
              }
            }
          }
        }
      }
      .side-nav {
        &:hover {
          .nav-menu {
            li {
              margin-bottom: 16px;
            }
            .menu {
              min-width: unset;
              .side-bar-content {
                display: flex;
                gap: 10px;
                flex-direction: row;
                align-items: center;
                .nav-text {
                  display: unset;
                  font-size: 14px;
                }
              }
            }
          }
        }
        .nav-menu {
          .menu {
            min-width: unset;
            .side-bar-content {
              display: flex;
              gap: 5px;
              //flex-direction: column;
              align-items: center;
              .nav-text {
                display: unset;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $mini-drawer-width;
      right: 0;
    }

    .app-sidebar {
      width: $mini-drawer-width;
      min-width: $mini-drawer-width;
      max-width: $mini-drawer-width;
    }
  }
}

.app-header-horizontal {
  & .app-main-header {
    left: auto !important;
    position: relative !important;
    right: auto !important;

    & + .app-top-nav {
      @include box-shadow(0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12));
    }
  }

  & .app-top-nav {
    padding: 0 24px;
    position: relative;
    z-index: 1101;

    & .app-toolbar {
      min-height: $app-bar-height - 30px;
      height: $app-bar-height - 30px;
      max-height: $app-bar-height - 30px;
    }
  }

  & .app-main-header-top {
    z-index: 1102;
  }
}