/*.app1-main-content {*/
/*    overflow: hidden !important;*/
/*}*/
@media (min-width: 1200px) {
    .tickets-btn {
        display: none;
    }
}
@media (max-width: 768px) {
    .statics {
        display: none;
    }
}
.chat-sidenav-main{
    width: 310px
}
@media (max-width: 375px){
    .chat-sidenav-main{
        width: 260px
    }
    /*.chat-main-header-info{*/
    /*    display: none;*/
    /*}*/
}
.chat-list-scroll.scrollbar > div:first-child{
    overflow-x: hidden !important
}

.badge {
    font-weight: 400;
    padding: 0px 9px;
    line-height: inherit;
    margin: 0px 4px 0px 0px;
    border-radius: 0.125rem;
}
.badge-pill {
    display: flex;
    border-radius: 20px;
    width: 108px;
    height: 33px;
    align-items: center;
    justify-content: space-around;
}
.counter {
    background-color: #367739 !important;
    color: #fff !important;
}
.badge--inside {
    display: inline-flex;
    align-items: center;
}